import React, { FC } from "react"

import { Layout } from "../components/layout"
import { Seo } from "../components/seo"

import { LoginForm } from "../components/LoginForm"
import { useUser } from "../hooks/useUser"
import { Liste } from "../components/Liste"

const IndexPage: FC = () => {
  const { user } = useUser();
  return (
    <Layout>
      <Seo title="Home" />
      <div className="container">
        <div>{ !user && (<LoginForm />)}</div>
        <div>{ user && (<Liste />)}</div>
      </div>
    </Layout>
  )
}

export default IndexPage
