import { ProductGroups } from "./ProductGroups"

export interface Category {
  id: ProductGroups
  name: string
  image: { path: string }
  brochures: Document[]
  pricelists: Document[]
}

interface Document {
  name: string
  file: string
  preview?: string
}

export interface RemoteCategory {
  id: ProductGroups
  name: string
  image: { path: string }
  brochures: { value: RemoteDocument }[]
  pricelists: { value: RemoteDocument }[]
  new: boolean
  year?: string
}

export interface RemoteDocument {
  name: string
  file: string
  language?: string
  preview?: {
    path: string
  }
}

export const data: Category[] = [
  {
    id: ProductGroups.Trommelmaehler,
    name: "Trommelmähler",
    image: {
      path: "/kuhn_preislisten/Vorschaubilder/Trommelmäher/KUHN_Trommelmaeher_vs.jpg",
    },
    brochures: [
      {
        name: "Prospekt PZ_321 F_PZ_320_DLZ",
        file: "/kuhn_preislisten/Prospekte/Trommelmäher/PZ.pdf",
        preview: "/kuhn_preislisten/Prospekte/Trommelmäher/vorschau/PZ.jpg",
      },
      {
        name: "Prospekt PZ",
        file: "/kuhn_preislisten/Prospekte/Trommelmäher/PZ_321 F_PZ_320_DLZ.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Trommelmäher/vorschau/PZ_321 F_PZ_320_DLZ.jpg",
      },
    ],
    pricelists: [
      {
        name: "Preisliste",
        file: "/kuhn_preislisten/Preislisten/1_1__Trommelmäwerke_2020_01.pdf",
      },
    ],
  },
  {
    id: ProductGroups.Scheibenmaeher,
    name: "Scheibenmäher",
    image: {
      path: "/kuhn_preislisten/Vorschaubilder/Scheibenmäher/KUHN_scheibenmaeher_vs.jpg",
    },
    brochures: [
      {
        name: "Prospekt FC_243-283-313",
        file: "/kuhn_preislisten/Prospekte/Scheibenmäher/FC_243-283-313.pdf",
        preview: "/kuhn_preislisten/Prospekte/Scheibenmäher/FC_243-283-313.pdf",
      },
      {
        name: "Prospekt FC_280 F-313 F",
        file: "/kuhn_preislisten/Prospekte/Scheibenmäher/FC_280 F-313 F.pdf",
        preview: "/kuhn_preislisten/Prospekte/Scheibenmäher/FC_280 F-313 F.pdf",
      },
      {
        name: "Prospekt FC_1060 TC",
        file: "/kuhn_preislisten/Prospekte/Scheibenmäher/FC_1060 TC.pdf",
        preview: "/kuhn_preislisten/Prospekte/Scheibenmäher/FC_1060 TC.pdf",
      },
      {
        name: "Prospekt FC_LIFT CONTROL",
        file: "/kuhn_preislisten/Prospekte/Scheibenmäher/FC_LIFT CONTROL.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Scheibenmäher/FC_LIFT CONTROL.pdf",
      },
      {
        name: "Prospekt GMD 110F - GMD 1020 F Compact",
        file: "/kuhn_preislisten/Prospekte/Scheibenmäher/GMD 110F - GMD 1020 F Compact.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Scheibenmäher/GMD 110F - GMD 1020 F Compact.pdf",
      },
      {
        name: "Prospekt GMD_10-100",
        file: "/kuhn_preislisten/Prospekte/Scheibenmäher/GMD_10-100.pdf",
        preview: "/kuhn_preislisten/Prospekte/Scheibenmäher/GMD_10-100.pdf",
      },
      {
        name: "Prospekt GMD_FC",
        file: "/kuhn_preislisten/Prospekte/Scheibenmäher/GMD_FC.pdf",
        preview: "/kuhn_preislisten/Prospekte/Scheibenmäher/GMD_FC.pdf",
      },
      {
        name: "Prospekt GMD_LIFT CONTROL",
        file: "/kuhn_preislisten/Prospekte/Scheibenmäher/GMD_LIFT CONTROL.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Scheibenmäher/vorschau/GMD_LIFT CONTROL.pdf",
      },
    ],
    pricelists: [
      {
        name: "Preisliste",
        file: "/kuhn_preislisten/Preislisten/1_2__Scheibenmäher_09-2020_21-komprimiert.pdf",
      },
    ],
  },
  {
    id: ProductGroups.Landschaftspflege,
    name: "Landschaftspflege",
    image: { path: "/kuhn_preislisten/Vorschaubilder/Landschaftspflege.jpg" },
    brochures: [],
    pricelists: [
      {
        name: "Preisliste",
        file: "/kuhn_preislisten/Preislisten/Landschaftspflege_2020_21-min.pdf",
      },
    ],
  },
  {
    id: ProductGroups.Soloaufbereiter,
    name: "Soloaufbereiter",
    image: {
      path: "/kuhn_preislisten/Vorschaubilder/Soloaufbereiter/Soloaufbereiter-5.jpg",
    },
    brochures: [
      {
        name: "Broschüre",
        file: "/kuhn_preislisten/Prospekte/Soloaufbereiter/Soloaufbereiter.pdf",
      },
    ],
    pricelists: [
      {
        name: "Preisliste",
        file: "/kuhn_preislisten/Preislisten/1_5__Soloaufbereiter_2020_21.pdf",
      },
    ],
  },
  {
    id: ProductGroups.Kreiselheuer,
    name: "Kreiselheuer",
    image: {
      path: "/kuhn_preislisten/Vorschaubilder/Kreiselheuer/KUHN_kreiselheuer_vs.jpg",
    },
    brochures: [
      {
        name: "Prospekt GF_102-1002-1012",
        file: "/kuhn_preislisten/Prospekte/Kreiselheuer/GF_102-1002-1012.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Kreiselheuer/vorschau/GF_102-1002-1012.jpg",
      },
      {
        name: "Prospekt GF_10802_DLZ",
        file: "/kuhn_preislisten/Prospekte/Kreiselheuer/GF_10802_DLZ.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Kreiselheuer/vorschau/GF_10802_DLZ.jpg",
      },
    ],
    pricelists: [
      {
        name: "Preisliste",
        file: "/kuhn_preislisten/Preislisten/1_3__Kreiselheuer_09-2020_21.pdf",
      },
    ],
  },
  {
    id: ProductGroups.Kreiselschwader,
    name: "Kreiselschwader",
    image: {
      path: "/kuhn_preislisten/Vorschaubilder/Kreiselschwader/KUHN_kreiselschwad_vs.jpg",
    },
    brochures: [
      {
        name: "Prospekt GA_1 Kreisel",
        file: "/kuhn_preislisten/Prospekte/Kreiselschwader/GA_1 KREISEL.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Kreiselschwader/vorschau/GA_1 KREISEL.jpg",
      },
      {
        name: "Prospekt GA_2 Kreisel",
        file: "/kuhn_preislisten/Prospekte/Kreiselschwader/GA_2 KREISEL.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Kreiselschwader/vorschau/GA_2 KREISEL.jpg",
      },
      {
        name: "Prospekt GA_8030_DLZ",
        file: "/kuhn_preislisten/Prospekte/Kreiselschwader/GA_8030_DLZ.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Kreiselschwader/vorschau/GA_8030_DLZ.jpg",
      },
      {
        name: "Prospekt GA_13131-15131",
        file: "/kuhn_preislisten/Prospekte/Kreiselschwader/GA_13131-15131.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Kreiselschwader/vorschau/GA_13131-15131.jpg",
      },
      {
        name: "Prospekt Merge Maxx",
        file: "/kuhn_preislisten/Prospekte/Kreiselschwader/MERGE MAXX.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Kreiselschwader/vorschau/MERGE MAXX.jpg",
      },
    ],
    pricelists: [
      {
        name: "Preisliste",
        file: "/kuhn_preislisten/1_4__Schwader_09-2020_21-komprimiert.pdf",
      },
    ],
  },
  {
    id: ProductGroups.Maishaecksler,
    name: "Maishäcksler",
    image: {
      path: "/kuhn_preislisten/Vorschaubilder/Maishäcksler/KUHN_maishaecksler_vs.jpg",
    },
    brochures: [
      {
        name: "Broschüre",
        file: "/kuhn_preislisten/Prospekte/Maishäcksler/MC_90 S-90 S TWIN-180 S QUATTRO.pdf",
      },
    ],
    pricelists: [
      {
        name: "Preisliste",
        file: "kuhn_preislisten/Preislisten/1_7_Maishäcksler_2020_21.pdf",
      },
    ],
  },
  {
    id: ProductGroups.Haecksler_Mulcher,
    name: "Häcksler & Mulcher",
    image: {
      path: "/kuhn_preislisten/Vorschaubilder/Häcksler & Mulcher/KUHN_haecksler_vs.jpg",
    },
    brochures: [],
    pricelists: [
      {
        name: "Preisliste",
        file: "/kuhn_preislisten/Preislisten/Mulchen_2020.pdf",
      },
    ],
  },
  {
    id: ProductGroups.Rund_Quaderballenpressen,
    name: "Rund- und Quaderballenpressen",
    image: {
      path: "/kuhn_preislisten/Vorschaubilder/Pressen/KUHN_pressen_vs.jpg",
    },
    brochures: [
      {
        name: "Prospekt FB_3130-3135",
        file: "/kuhn_preislisten/Prospekte/FB-FBP_3130-3135.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Pressen/vorschau/FB-FBP_3130-3135_vs.jpg",
      },
      {
        name: "Prospekt FB-FBP_2135",
        file: "/kuhn_preislisten/Prospekte/FB-FBP_2135.pdf",
        preview: "/kuhn_preislisten/Prospekte/Pressen/vorschau/FB-FBP_2135.jpg",
      },
      {
        name: "Prospekt I-BIO+",
        file: "/kuhn_preislisten/Prospekte/Pressen/I-BIOplus.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Pressen/vorschau/I-BIOplus_vs.jpg",
      },
      {
        name: "Prospekt LSB iD",
        file: "/kuhn_preislisten/Prospekte/Pressen/LSB iD.pdf",
        preview: "/kuhn_preislisten/Prospekte/Pressen/vorschau/LSB iD.jpg",
      },
      {
        name: "Prospekt LSB",
        file: "/kuhn_preislisten/Prospekte/Pressen/LSB.pdf",
        preview: "/kuhn_preislisten/Prospekte/Pressen/vorschau/LSB.jpg",
      },
      {
        name: "Prospekt VB-VBP_2200",
        file: "/kuhn_preislisten/Prospekte/Pressen/VB-VBP_2200.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Pressen/vorschau/VB-VBP_2200_vs.jpg",
      },
    ],
    pricelists: [
      {
        name: "Preisliste",
        file: "/kuhn_preislisten/2_0_Pressen_2020_21-komprimiert_01.pdf",
      },
    ],
  },
  {
    id: ProductGroups.Wickler,
    name: "Wickler",
    image: {
      path: "/kuhn_preislisten/Vorschaubilder/Wickler/KUHN_wickler_vs.jpg",
    },
    brochures: [
      {
        name: "Prospekt",
        file: "/kuhn_preislisten/Prospekte/Wickler/RW-SW.pdf",
      },
    ],
    pricelists: [
      {
        name: "Preisliste",
        file: "/kuhn_preislisten/Preislisten/2_2_Wickelmaschinen_2020_21.pdf",
      },
    ],
  },
  {
    id: ProductGroups.Pfluege,
    name: "Pflüge",
    image: {
      path: "/kuhn_preislisten/Vorschaubilder/Pflüge/KUHN_pfluege_vs.jpg",
    },
    brochures: [
      {
        name: "Prospekt Leader",
        file: "/kuhn_preislisten/Prospekte/Pflüge/LEADER.pdf",
        preview: "/kuhn_preislisten/Prospekte/Pflüge/vorschau/LEADER.jpg",
      },
      {
        name: "Prospekt Manager_Challenger",
        file: "/kuhn_preislisten/Prospekte/Pflüge/MANAGER CHALLENGER.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Pflüge/vorschau/MANAGER CHALLENGER.jpg",
      },
      {
        name: "Prospekt Master_103",
        file: "/kuhn_preislisten/Prospekte/Pflüge/MASTER_103.pdf",
        preview: "/kuhn_preislisten/Prospekte/Pflüge/vorschau/MASTER_103.jpg",
      },
      {
        name: "Prospekt Master 113",
        file: "/kuhn_preislisten/Prospekte/Pflüge/MASTER_113.pdf",
        preview: "/kuhn_preislisten/Prospekte/Pflüge/vorschau/MASTER_113.jpg",
      },
      {
        name: "Prospekt Master_123-153",
        file: "/kuhn_preislisten/Prospekte/Pflüge/MASTER_123-153.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Pflüge/vorschau/MASTER_123-153.jpg",
      },
      {
        name: "Prospekt Master_183",
        file: "/kuhn_preislisten/Prospekte/Pflüge/MASTER_183.pdf",
        preview: "/kuhn_preislisten/Prospekte/Pflüge/vorschau/MASTER_183.jpg",
      },
    ],
    pricelists: [
      {
        name: "Preisliste",
        file: "/kuhn_preislisten/Preislisten/3_1_Pflüge_2020-21_V2-komprimiert.pdf",
      },
    ],
  },
  {
    id: ProductGroups.Entnahme_Verteilmaschinen,
    name: "Entnahme- und Verteilmaschinen",
    image: {
      path: "/kuhn_preislisten/Vorschaubilder/Entnahme- und Verteilermaschinen/KUHN_entnahme_vs.jpg",
    },
    brochures: [
      {
        name: "Prospekt Altor",
        file: "/kuhn_preislisten/Prospekte/Entnahme- und Verteilermaschinen/ALTOR.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Entnahme- und Verteilermaschinen/vorschau/ALTOR.jpg",
      },
      {
        name: "Prospekt Polycvork",
        file: "/kuhn_preislisten/Prospekte/Entnahme- und Verteilermaschinen/POLYCROK.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Entnahme- und Verteilermaschinen/vorschau/POLYCROK.jpg",
      },
      {
        name: "Prospekt Primor H",
        file: "/kuhn_preislisten/Prospekte/Entnahme- und Verteilermaschinen/PRIMOR H.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Entnahme- und Verteilermaschinen/vorschau/PRIMOR H.jpg",
      },
      {
        name: "Prospekt Primor M",
        file: "/kuhn_preislisten/Prospekte/Entnahme- und Verteilermaschinen/PRIMOR M.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Entnahme- und Verteilermaschinen/vorschau/PRIMOR M.jpg",
      },
    ],
    pricelists: [
      {
        name: "Preisliste",
        file: "/kuhn_preislisten/Preislisten/Entnahme-_und_Verteilmaschinen_compressed.pdf",
      },
    ],
  },
  {
    id: ProductGroups.Bodenbearbeitung,
    name: "Bodenbearbeitung",
    image: {
      path: "/kuhn_preislisten/Vorschaubilder/Bodenbearbeitung/Cultimer300.jpg",
    },
    brochures: [
      {
        name: "Prospekt CD_300-400",
        file: "/kuhn_preislisten/Prospekte/Bodenbearbeitung/CD_300-400.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Bodenbearbeitung/vorschau/CD_300-400.jpg",
      },
      {
        name: "Prospekt Cultimer_100",
        file: "/kuhn_preislisten/Prospekte/Bodenbearbeitung/CULTIMER_100.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Bodenbearbeitung/vorschau/CULTIMER_100.jpg",
      },
      {
        name: "Prospekt Cultimer_L",
        file: "/kuhn_preislisten/Prospekte/Bodenbearbeitung/CULTIMER_L.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Bodenbearbeitung/vorschau/CULTIMER_L.jpg",
      },
      {
        name: "Prospekt Cultisoil_DC 301 - 401",
        file: "/kuhn_preislisten/Prospekte/Bodenbearbeitung/CULTISOIL_DC_301-401.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Bodenbearbeitung/vorschau/CULTISOIL_DC_301-401.jpg",
      },
      {
        name: "Prospekt DISCOVER XM2; DISCOLANDER XM",
        file: "/kuhn_preislisten/Prospekte/Bodenbearbeitung/DISCOLANDER.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Bodenbearbeitung/vorschau/DISCOLANDER.jpg",
      },
      {
        name: "Prospekt Discover XM2, XL2",
        file: "/kuhn_preislisten/Prospekte/Bodenbearbeitung/DISCOVER.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Bodenbearbeitung/vorschau/DISCOVER.jpg",
      },
      {
        name: "Prospekt EL_43-53-62-82-92 / HRB_122-152-182-202",
        file: "/kuhn_preislisten/Prospekte/Bodenbearbeitung/EL_43-53-62-82-92_HRB_122-152-182-202.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Bodenbearbeitung/vorschau/EL_43-53-62-82-92_HRB_122-152-182-202.jpg",
      },
      {
        name: "Prospekt EL_122-162-282",
        file: "/kuhn_preislisten/Prospekte/Bodenbearbeitung/EL_122-162-282.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Bodenbearbeitung/vorschau/EL_122-162-282.jpg",
      },
      {
        name: "Prospekt HRB_102-103_HR_104-1004",
        file: "/kuhn_preislisten/Prospekte/Bodenbearbeitung/HRB_102-103_HR_104-1004.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Bodenbearbeitung/vorschau/HRB_102-103_HR_104-1004.jpg",
      },
      {
        name: "Prospekt Optimer+ Serien 103, 103R und 1003",
        file: "/kuhn_preislisten/Prospekte/Bodenbearbeitung/OPTIMER+.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Bodenbearbeitung/vorschau/OPTIMER+.jpg",
      },
      {
        name: "Prospekt Performer",
        file: "/kuhn_preislisten/Prospekte/Bodenbearbeitung/PERFORMER.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Bodenbearbeitung/vorschau/PERFORMER.jpg",
      },
      {
        name: "Prospekt Striger",
        file: "/kuhn_preislisten/Prospekte/Bodenbearbeitung/STRIGER.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Bodenbearbeitung/vorschau/STRIGER.jpg",
      },
    ],
    pricelists: [
      {
        name: "Preisliste Kreiseleggen",
        file: "/kuhn_preislisten/Preislisten/3_2_Kreiseleggen_2020-21_V2-komprimiert.pdf",
      },
      {
        name: "Preisliste Bodenfräsen",
        file: "/kuhn_preislisten/Preislisten/3_5_Bodenfr%C3%A4sen_2020-21-V1_compressed.pdf",
      },
      {
        name: "Preisliste Bodenbearbeitung",
        file: "/kuhn_preislisten/Preislisten/3_6_Bodenbearbeitung_2020-21_V1_compressed.pdf",
      },
      {
        name: "Preisliste Kurzkombination",
        file: "/kuhn_preislisten/Preislisten/3_6_Kurzkombination_2020-21_V1_compressed.pdf",
      },
    ],
  },
  {
    id: ProductGroups.Drillmaschinen,
    name: "Drillmaschinen",
    image: {
      path: "/kuhn_preislisten/Vorschaubilder/Drillmaschinen/KUHN_drillsae_vs.jpg",
    },
    brochures: [
      {
        name: "Prospekt Espro",
        file: "/kuhn_preislisten/Prospekte/Drillmaschinen/ESPRO.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Drillmaschinen/vorschau/ESPRO.jpg",
      },
      {
        name: "Prospekt Integra",
        file: "/kuhn_preislisten/Prospekte/Drillmaschinen/INTEGRA.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Drillmaschinen/vorschau/INTEGRA.jpg",
      },
      {
        name: "Prospekt Megant",
        file: "/kuhn_preislisten/Prospekte/Drillmaschinen/MEGANT.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Drillmaschinen/vorschau/MEGANT.jpg",
      },
      {
        name: "Prospekt Premia",
        file: "/kuhn_preislisten/Prospekte/Drillmaschinen/PREMIA.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Drillmaschinen/vorschau/PREMIA.jpg",
      },
      {
        name: "Prospekt SD_3000-4000-4500-6000",
        file: "/kuhn_preislisten/Prospekte/Drillmaschinen/SD_3000-4000-4500-6000.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Drillmaschinen/vorschau/SD_3000-4000-4500-6000.jpg",
      },
      {
        name: "Prospekt Sitera",
        file: "/kuhn_preislisten/Prospekte/Drillmaschinen/SITERA.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Drillmaschinen/vorschau/SITERA.jpg",
      },
      {
        name: "Prospekt Sitera_DLG",
        file: "/kuhn_preislisten/Prospekte/Drillmaschinen/SITERA_DLG.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Drillmaschinen/vorschau/SITERA_DLG.jpg",
      },
      {
        name: "Prospekt TF_1500_TT_3500-6500_BTF",
        file: "/kuhn_preislisten/Prospekte/Drillmaschinen/TF_1500_TT_3500-6500_BTF.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Drillmaschinen/vorschau/TF_1500_TT_3500-6500_BTF.jpg",
      },
      {
        name: "Prospekt Venta_CSC_Moduliner",
        file: "/kuhn_preislisten/Prospekte/Drillmaschinen/VENTA_CSC_MODULINER.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Drillmaschinen/vorschau/VENTA_CSC_MODULINER.jpg",
      },
      {
        name: "Prospekt Venta_EC-LC-NC",
        file: "/kuhn_preislisten/Prospekte/Drillmaschinen/VENTA_EC-LC-NC.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Drillmaschinen/vorschau/VENTA_EC-LC-NC.jpg",
      },
    ],
    pricelists: [
      {
        name: "Preisliste",
        file: "/kuhn_preislisten/Preislisten/3_3_Drillmaschinen_2020-21_V1_compressed.pdf",
      },
    ],
  },
  {
    id: ProductGroups.Einzelkornsaemaschinen,
    name: "Einzelkornsämaschinen",
    image: {
      path: "/kuhn_preislisten/Vorschaubilder/Einzelkornsämaschinen/KUHN_einzelkorn_vs.jpg",
    },
    brochures: [
      {
        name: "Prospekt MAXIMA 2",
        file: "/kuhn_preislisten/Prospekte/Einzelkornsämaschinen/MAXIMA.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Einzelkornsämaschinen/vorschau/Maxima2.png",
      },
      {
        name: "Prospekt Planter",
        file: "/kuhn_preislisten/Prospekte/Einzelkornsämaschinen/PLANTER.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Einzelkornsämaschinen/vorschau/Planter3.png",
      },
    ],
    pricelists: [
      {
        name: "Preisliste",
        file: "/kuhn_preislisten/Preislisten/3_4_Einzelkornsämaschine_2020-21_compressed.pdf",
      },
    ],
  },
  {
    id: ProductGroups.Futtermischwagen,
    name: "Futtermischwagen",
    image: {
      path: "/kuhn_preislisten/Vorschaubilder/Gezogene Futtermischwagen/KUHN_gez_futtermisch_vs.jpg",
    },
    brochures: [
      {
        name: "Prospekt Centramix",
        file: "/kuhn_preislisten/Prospekte/Gezogene Futtermischwagen/CENTRAMIX.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Gezogene Futtermischwagen/vorschau/CENTRAMIX.jpg",
      },
      {
        name: "Prospekt Euromix I",
        file: "/kuhn_preislisten/Prospekte/Gezogene Futtermischwagen/EUROMIX I.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Gezogene Futtermischwagen/vorschau/EUROMIX I.jpg",
      },
      {
        name: "Prospekt Euromix II",
        file: "/kuhn_preislisten/Prospekte/Gezogene Futtermischwagen/EUROMIX II.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Gezogene Futtermischwagen/vorschau/EUROMIX II.jpg",
      },
      {
        name: "Prospekt Profile",
        file: "/kuhn_preislisten/Prospekte/Gezogene Futtermischwagen/PROFILE.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Gezogene Futtermischwagen/vorschau/PROFILE.jpg",
      },
    ],
    pricelists: [
      {
        name: "Preisliste",
        file: "/kuhn_preislisten/Preislisten/4_2_Fütterungstechnik.pdf",
      },
    ],
  },
  {
    id: ProductGroups.Feldspritzen,
    name: "Feldspritzen",
    image: {
      path: "/kuhn_preislisten/Vorschaubilder/Anbaufeldspritzen/KUHN_anbaufeld_vs.jpg",
    },
    brochures: [
      {
        name: "Prospekt ALTIS 2002 + PF 1000/1500",
        file: "/kuhn_preislisten/Prospekte/Anbaufeldspritzen/ALTIS_PF.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Anbaufeldspritzen/vorschau/ALTIS_PF.jpg",
      },
      {
        name: "Prospekt DELTIS 1000 & 1200 l; ALTIS 1300, 1600 & 1800 l",
        file: "/kuhn_preislisten/Prospekte/Anbaufeldspritzen/DELTIS_ALTIS.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Anbaufeldspritzen/vorschau/DELTIS_ALTIS.jpg",
      },
      {
        name: "Prospekt OMNIS 600, 800, 1000 & 1200 Liter",
        file: "/kuhn_preislisten/Prospekte/Anbaufeldspritzen/OMNIS.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Anbaufeldspritzen/vorschau/OMNIS.jpg",
      },
      {
        name: "Prospekt OPTIS 600, 800, 1000 Liter",
        file: "/kuhn_preislisten/Prospekte/Anbaufeldspritzen/OPTIS.pdf",
        preview:
          "/kuhn_preislisten/Prospekte/Anbaufeldspritzen/vorschau/OPTIS.jpg",
      },
    ],
    pricelists: [
      {
        name: "Preisliste",
        file: "/kuhn_preislisten/Preislisten/5_1_Feldspritzen_2018-19.pdf",
      },
    ],
  },
]
