import { ProductGroups } from "./ProductGroups"

export const users = [
  {
    id: "kuhn_new",
    name: "Preisliste Gesamt",
    password: "kugesnew",
    groups: [
      ProductGroups.Trommelmaehler,
      ProductGroups.Scheibenmaeher,
      ProductGroups.Soloaufbereiter,
      ProductGroups.Kreiselheuer,
      ProductGroups.Kreiselschwader,
      ProductGroups.Maishaecksler,
      ProductGroups.Haecksler_Mulcher,
      ProductGroups.Landschaftspflege,
      ProductGroups.Pfluege,
      ProductGroups.Rund_Quaderballenpressen,
      ProductGroups.Entnahme_Verteilmaschinen,
      ProductGroups.Wickler,
      ProductGroups.Bodenbearbeitung,
      ProductGroups.Drillmaschinen,
      ProductGroups.Einzelkornsaemaschinen,
      ProductGroups.Futtermischwagen,
      ProductGroups.Feldspritzen,
    ],
    new: true,
    expires: "2021-09-03T12:00:00.000Z",
    active: null,
  },
  /* {
    id: "kuhn1",
    name: "Preisliste Gesamt",
    password: "kuges222",
    groups: [ProductGroups.Trommelmaehler, ProductGroups.Scheibenmaeher, ProductGroups.Soloaufbereiter, ProductGroups.Kreiselheuer, ProductGroups.Kreiselschwader, ProductGroups.Maishaecksler, ProductGroups.Haecksler_Mulcher, ProductGroups.Landschaftspflege, ProductGroups.Pfluege, ProductGroups.Rund_Quaderballenpressen, ProductGroups.Entnahme_Verteilmaschinen, ProductGroups.Wickler, ProductGroups.Bodenbearbeitung, ProductGroups.Drillmaschinen, ProductGroups.Einzelkornsaemaschinen, ProductGroups.Futtermischwagen, ProductGroups.Feldspritzen],
    new: false,
    expires: "2021-09-03T12:00:00.000Z",
    active: null
  },
  {
    id: "kuhn2",
    name: "Preisliste Futterernte",
    password: "kufu222",
    groups: [ProductGroups.Trommelmaehler, ProductGroups.Scheibenmaeher, ProductGroups.Soloaufbereiter, ProductGroups.Kreiselheuer, ProductGroups.Kreiselschwader, ProductGroups.Maishaecksler, ProductGroups.Haecksler_Mulcher, ProductGroups.Landschaftspflege],
    new: false,
    expires: "2021-09-03T12:00:00.000Z",
    active: null
  },
  {
    id: "kuhn3",
    name: "Preisliste Futterernte inkl. Press- und Wickeltechnik",
    password: "kufuprwi222",
    groups: [ProductGroups.Trommelmaehler, ProductGroups.Scheibenmaeher, ProductGroups.Soloaufbereiter, ProductGroups.Kreiselheuer, ProductGroups.Kreiselschwader, ProductGroups.Maishaecksler, ProductGroups.Haecksler_Mulcher, ProductGroups.Landschaftspflege, ProductGroups.Rund_Quaderballenpressen, ProductGroups.Wickler],
    new: false,
    expires: "2021-09-03T12:00:00.000Z",
    active: null
  },
  {
    id: "kuhn4",
    name: "Preisliste Futterernte inkl. Fütterungstechnik",
    password: "kufufue222",
    groups: [ProductGroups.Trommelmaehler, ProductGroups.Scheibenmaeher, ProductGroups.Soloaufbereiter, ProductGroups.Kreiselheuer, ProductGroups.Kreiselschwader, ProductGroups.Maishaecksler, ProductGroups.Haecksler_Mulcher, ProductGroups.Landschaftspflege, ProductGroups.Entnahme_Verteilmaschinen, ProductGroups.Futtermischwagen],
    new: false,
    expires: "2021-09-03T12:00:00.000Z",
    active: null
  },
  {
    id: "kuhn5",
    name: "Preisliste Futterernte inkl. Press. und Wickeltechnik und Fütterungtechnik",
    password: "kufuprfue222",
    groups: [ProductGroups.Trommelmaehler, ProductGroups.Scheibenmaeher, ProductGroups.Soloaufbereiter, ProductGroups.Kreiselheuer, ProductGroups.Kreiselschwader, ProductGroups.Maishaecksler, ProductGroups.Haecksler_Mulcher, ProductGroups.Landschaftspflege, ProductGroups.Rund_Quaderballenpressen, ProductGroups.Entnahme_Verteilmaschinen, ProductGroups.Wickler, ProductGroups.Futtermischwagen],
    new: false,
    expires: "2021-09-03T12:00:00.000Z",
    active: null
  },
  {
    id: "kuhn6",
    name: "Preisliste Ackerbau",
    password: "kuac222",
    groups: [ProductGroups.Haecksler_Mulcher, ProductGroups.Landschaftspflege, ProductGroups.Pfluege, ProductGroups.Bodenbearbeitung, ProductGroups.Drillmaschinen, ProductGroups.Feldspritzen],
    new: false,
    expires: "2021-09-03T12:00:00.000Z",
    active: null
  },
  {
    id: "kuhn7",
    name: "Preisliste Ackerbau inkl. Einzelkornsätechnik",
    password: "kuacei222",
    groups: [ProductGroups.Haecksler_Mulcher, ProductGroups.Landschaftspflege, ProductGroups.Pfluege, ProductGroups.Bodenbearbeitung, ProductGroups.Drillmaschinen, ProductGroups.Einzelkornsaemaschinen, ProductGroups.Feldspritzen],
    new: false,
    expires: "2021-09-03T12:00:00.000Z",
    active: null
  },
  {
    id: "kuhn8",
    name: "Preisliste Press- und Wickeltechnik",
    password: "kuprwi222",
    groups: [ProductGroups.Rund_Quaderballenpressen, ProductGroups.Wickler],
    new: false,
    expires: "2021-09-03T12:00:00.000Z",
    active: null
  },
  {
    id: "kuhn9",
    name: "Preisliste Einzelkornsätechnik",
    password: "kuei222",
    groups: [ProductGroups.Einzelkornsaemaschinen],
    new: false,
    expires: "2021-09-03T12:00:00.000Z",
    active: null
  },
  {
    id: "kuhn10",
    name: "Preisliste Gesamt ohne Pressen",
    password: "kugeopr222",
    groups: [ProductGroups.Trommelmaehler, ProductGroups.Scheibenmaeher, ProductGroups.Soloaufbereiter, ProductGroups.Kreiselheuer, ProductGroups.Kreiselschwader, ProductGroups.Maishaecksler, ProductGroups.Haecksler_Mulcher, ProductGroups.Landschaftspflege, ProductGroups.Pfluege, ProductGroups.Entnahme_Verteilmaschinen, ProductGroups.Bodenbearbeitung, ProductGroups.Drillmaschinen, ProductGroups.Einzelkornsaemaschinen, ProductGroups.Futtermischwagen, ProductGroups.Feldspritzen],
    new: false,
    expires: "2021-09-03T12:00:00.000Z",
    active: null
  }, {
    id: "kuhn11",
    name: "Preisliste Gesamt ohne Einzelkornsämaschinen",
    password: "kugeoek222",
    groups: [ProductGroups.Trommelmaehler, ProductGroups.Scheibenmaeher, ProductGroups.Soloaufbereiter, ProductGroups.Kreiselheuer, ProductGroups.Kreiselschwader, ProductGroups.Maishaecksler, ProductGroups.Haecksler_Mulcher, ProductGroups.Landschaftspflege, ProductGroups.Pfluege, ProductGroups.Rund_Quaderballenpressen, ProductGroups.Entnahme_Verteilmaschinen, ProductGroups.Wickler, ProductGroups.Bodenbearbeitung, ProductGroups.Drillmaschinen, ProductGroups.Futtermischwagen, ProductGroups.Feldspritzen],
    new: false,
    expires: "2021-09-03T12:00:00.000Z",
    active: null
  },*/ {
    id: "kuhn1",
    name: "Preisliste Gesamt",
    password: "kuges470",
    groups: [
      ProductGroups.Trommelmaehler,
      ProductGroups.Scheibenmaeher,
      ProductGroups.Soloaufbereiter,
      ProductGroups.Kreiselheuer,
      ProductGroups.Kreiselschwader,
      ProductGroups.Maishaecksler,
      ProductGroups.Haecksler_Mulcher,
      ProductGroups.Landschaftspflege,
      ProductGroups.Pfluege,
      ProductGroups.Rund_Quaderballenpressen,
      ProductGroups.Entnahme_Verteilmaschinen,
      ProductGroups.Wickler,
      ProductGroups.Bodenbearbeitung,
      ProductGroups.Drillmaschinen,
      ProductGroups.Einzelkornsaemaschinen,
      ProductGroups.Futtermischwagen,
      ProductGroups.Feldspritzen,
    ],
    new: true,
    languages: ["DE"],
    expires: null,
    active: null,
  },
  {
    id: "kuhn2",
    name: "Preisliste Futterernte",
    password: "kufu470",
    groups: [
      ProductGroups.Trommelmaehler,
      ProductGroups.Scheibenmaeher,
      ProductGroups.Soloaufbereiter,
      ProductGroups.Kreiselheuer,
      ProductGroups.Kreiselschwader,
      ProductGroups.Maishaecksler,
      ProductGroups.Haecksler_Mulcher,
      ProductGroups.Landschaftspflege,
    ],
    new: true,
    languages: ["DE"],
    expires: null,
    active: null,
  },
  {
    id: "kuhn3",
    name: "Preisliste Futterernte inkl. Press- und Wickeltechnik",
    password: "kufuprwi470",
    groups: [
      ProductGroups.Trommelmaehler,
      ProductGroups.Scheibenmaeher,
      ProductGroups.Soloaufbereiter,
      ProductGroups.Kreiselheuer,
      ProductGroups.Kreiselschwader,
      ProductGroups.Maishaecksler,
      ProductGroups.Haecksler_Mulcher,
      ProductGroups.Landschaftspflege,
      ProductGroups.Rund_Quaderballenpressen,
      ProductGroups.Wickler,
    ],
    new: true,
    languages: ["DE"],
    expires: null,
    active: null,
  },
  {
    id: "kuhn4",
    name: "Preisliste Futterernte inkl. Fütterungstechnik",
    password: "kufufue470",
    groups: [
      ProductGroups.Trommelmaehler,
      ProductGroups.Scheibenmaeher,
      ProductGroups.Soloaufbereiter,
      ProductGroups.Kreiselheuer,
      ProductGroups.Kreiselschwader,
      ProductGroups.Maishaecksler,
      ProductGroups.Haecksler_Mulcher,
      ProductGroups.Landschaftspflege,
      ProductGroups.Entnahme_Verteilmaschinen,
      ProductGroups.Futtermischwagen,
    ],
    new: true,
    languages: ["DE"],
    expires: null,
    active: null,
  },
  {
    id: "kuhn5",
    name: "Preisliste Futterernte inkl. Press. und Wickeltechnik und Fütterungtechnik",
    password: "kufuprfue470",
    groups: [
      ProductGroups.Trommelmaehler,
      ProductGroups.Scheibenmaeher,
      ProductGroups.Soloaufbereiter,
      ProductGroups.Kreiselheuer,
      ProductGroups.Kreiselschwader,
      ProductGroups.Maishaecksler,
      ProductGroups.Haecksler_Mulcher,
      ProductGroups.Landschaftspflege,
      ProductGroups.Rund_Quaderballenpressen,
      ProductGroups.Entnahme_Verteilmaschinen,
      ProductGroups.Wickler,
      ProductGroups.Futtermischwagen,
    ],
    new: true,
    languages: ["DE"],
    expires: null,
    active: null,
  },
  {
    id: "kuhn6",
    name: "Preisliste Ackerbau",
    password: "kuac470",
    groups: [
      ProductGroups.Haecksler_Mulcher,
      ProductGroups.Landschaftspflege,
      ProductGroups.Pfluege,
      ProductGroups.Bodenbearbeitung,
      ProductGroups.Drillmaschinen,
      ProductGroups.Feldspritzen,
    ],
    new: true,
    languages: ["DE"],
    expires: null,
    active: null,
  },
  {
    id: "kuhn7",
    name: "Preisliste Ackerbau inkl. Einzelkornsätechnik",
    password: "kuacei470",
    groups: [
      ProductGroups.Haecksler_Mulcher,
      ProductGroups.Landschaftspflege,
      ProductGroups.Pfluege,
      ProductGroups.Bodenbearbeitung,
      ProductGroups.Drillmaschinen,
      ProductGroups.Einzelkornsaemaschinen,
      ProductGroups.Feldspritzen,
    ],
    new: true,
    languages: ["DE"],
    expires: null,
    active: null,
  },
  {
    id: "kuhn8",
    name: "Preisliste Press- und Wickeltechnik",
    password: "kuprwi470",
    groups: [ProductGroups.Rund_Quaderballenpressen, ProductGroups.Wickler],
    new: true,
    languages: ["DE"],
    expires: null,
    active: null,
  },
  {
    id: "kuhn9",
    name: "Preisliste Einzelkornsätechnik",
    password: "kuei470",
    groups: [ProductGroups.Einzelkornsaemaschinen],
    new: true,
    languages: ["DE"],
    expires: null,
    active: null,
  },
  {
    id: "kuhn10",
    name: "Preisliste Gesamt ohne Pressen",
    password: "kugeopr470",
    groups: [
      ProductGroups.Trommelmaehler,
      ProductGroups.Scheibenmaeher,
      ProductGroups.Soloaufbereiter,
      ProductGroups.Kreiselheuer,
      ProductGroups.Kreiselschwader,
      ProductGroups.Maishaecksler,
      ProductGroups.Haecksler_Mulcher,
      ProductGroups.Landschaftspflege,
      ProductGroups.Pfluege,
      ProductGroups.Entnahme_Verteilmaschinen,
      ProductGroups.Bodenbearbeitung,
      ProductGroups.Drillmaschinen,
      ProductGroups.Einzelkornsaemaschinen,
      ProductGroups.Futtermischwagen,
      ProductGroups.Feldspritzen,
    ],
    new: true,
    languages: ["DE"],
    expires: null,
    active: null,
  },
  {
    id: "kuhn11",
    name: "Preisliste Gesamt ohne Einzelkornsämaschinen",
    password: "kugeoek470",
    groups: [
      ProductGroups.Trommelmaehler,
      ProductGroups.Scheibenmaeher,
      ProductGroups.Soloaufbereiter,
      ProductGroups.Kreiselheuer,
      ProductGroups.Kreiselschwader,
      ProductGroups.Maishaecksler,
      ProductGroups.Haecksler_Mulcher,
      ProductGroups.Landschaftspflege,
      ProductGroups.Pfluege,
      ProductGroups.Rund_Quaderballenpressen,
      ProductGroups.Entnahme_Verteilmaschinen,
      ProductGroups.Wickler,
      ProductGroups.Bodenbearbeitung,
      ProductGroups.Drillmaschinen,
      ProductGroups.Futtermischwagen,
      ProductGroups.Feldspritzen,
    ],
    new: true,
    languages: ["DE"],
    expires: null,
    active: null,
  },
  // new users 2022
  {
    id: "kuhn1",
    name: "Preisliste Gesamt",
    password: "kuges236",
    groups: [
      ProductGroups.Trommelmaehler,
      ProductGroups.Scheibenmaeher,
      ProductGroups.Soloaufbereiter,
      ProductGroups.Kreiselheuer,
      ProductGroups.Kreiselschwader,
      ProductGroups.Maishaecksler,
      ProductGroups.Haecksler_Mulcher,
      ProductGroups.Landschaftspflege,
      ProductGroups.Pfluege,
      ProductGroups.Rund_Quaderballenpressen,
      ProductGroups.Entnahme_Verteilmaschinen,
      ProductGroups.Wickler,
      ProductGroups.Bodenbearbeitung,
      ProductGroups.Drillmaschinen,
      ProductGroups.Einzelkornsaemaschinen,
      ProductGroups.Futtermischwagen,
      ProductGroups.Feldspritzen,
    ],
    new: false,
    year: "22/23",
    languages: ["DE"],
    expires: null,
    active: null,
  },
  {
    id: "kuhn2",
    name: "Preisliste Futterernte",
    password: "kufu236",
    groups: [
      ProductGroups.Trommelmaehler,
      ProductGroups.Scheibenmaeher,
      ProductGroups.Soloaufbereiter,
      ProductGroups.Kreiselheuer,
      ProductGroups.Kreiselschwader,
      ProductGroups.Maishaecksler,
      ProductGroups.Haecksler_Mulcher,
      ProductGroups.Landschaftspflege,
    ],
    new: false,
    year: "22/23",
    languages: ["DE"],
    expires: null,
    active: null,
  },
  {
    id: "kuhn3",
    name: "Preisliste Futterernte inkl. Press- und Wickeltechnik",
    password: "kufuprwi236",
    groups: [
      ProductGroups.Trommelmaehler,
      ProductGroups.Scheibenmaeher,
      ProductGroups.Soloaufbereiter,
      ProductGroups.Kreiselheuer,
      ProductGroups.Kreiselschwader,
      ProductGroups.Maishaecksler,
      ProductGroups.Haecksler_Mulcher,
      ProductGroups.Landschaftspflege,
      ProductGroups.Rund_Quaderballenpressen,
      ProductGroups.Wickler,
    ],
    new: false,
    year: "22/23",
    languages: ["DE"],
    expires: null,
    active: null,
  },
  {
    id: "kuhn4",
    name: "Preisliste Futterernte inkl. Fütterungstechnik",
    password: "kufufue236",
    groups: [
      ProductGroups.Trommelmaehler,
      ProductGroups.Scheibenmaeher,
      ProductGroups.Soloaufbereiter,
      ProductGroups.Kreiselheuer,
      ProductGroups.Kreiselschwader,
      ProductGroups.Maishaecksler,
      ProductGroups.Haecksler_Mulcher,
      ProductGroups.Landschaftspflege,
      ProductGroups.Entnahme_Verteilmaschinen,
      ProductGroups.Futtermischwagen,
    ],
    new: false,
    year: "22/23",
    languages: ["DE"],
    expires: null,
    active: null,
  },
  {
    id: "kuhn5",
    name: "Preisliste Futterernte inkl. Press. und Wickeltechnik und Fütterungtechnik",
    password: "kufuprfue236",
    groups: [
      ProductGroups.Trommelmaehler,
      ProductGroups.Scheibenmaeher,
      ProductGroups.Soloaufbereiter,
      ProductGroups.Kreiselheuer,
      ProductGroups.Kreiselschwader,
      ProductGroups.Maishaecksler,
      ProductGroups.Haecksler_Mulcher,
      ProductGroups.Landschaftspflege,
      ProductGroups.Rund_Quaderballenpressen,
      ProductGroups.Entnahme_Verteilmaschinen,
      ProductGroups.Wickler,
      ProductGroups.Futtermischwagen,
    ],
    new: false,
    year: "22/23",
    languages: ["DE"],
    expires: null,
    active: null,
  },
  {
    id: "kuhn6",
    name: "Preisliste Ackerbau",
    password: "kuac236",
    groups: [
      ProductGroups.Haecksler_Mulcher,
      ProductGroups.Landschaftspflege,
      ProductGroups.Pfluege,
      ProductGroups.Bodenbearbeitung,
      ProductGroups.Drillmaschinen,
      ProductGroups.Feldspritzen,
    ],
    new: false,
    year: "22/23",
    languages: ["DE"],
    expires: null,
    active: null,
  },
  {
    id: "kuhn7",
    name: "Preisliste Ackerbau inkl. Einzelkornsätechnik",
    password: "kuacei236",
    groups: [
      ProductGroups.Haecksler_Mulcher,
      ProductGroups.Landschaftspflege,
      ProductGroups.Pfluege,
      ProductGroups.Bodenbearbeitung,
      ProductGroups.Drillmaschinen,
      ProductGroups.Einzelkornsaemaschinen,
      ProductGroups.Feldspritzen,
    ],
    new: false,
    year: "22/23",
    languages: ["DE"],
    expires: null,
    active: null,
  },
  {
    id: "kuhn8",
    name: "Preisliste Press- und Wickeltechnik",
    password: "kuprwi236",
    groups: [ProductGroups.Rund_Quaderballenpressen, ProductGroups.Wickler],
    new: false,
    year: "22/23",
    languages: ["DE"],
    expires: null,
    active: null,
  },
  {
    id: "kuhn9",
    name: "Preisliste Einzelkornsätechnik",
    password: "kuei236",
    groups: [ProductGroups.Einzelkornsaemaschinen],
    new: false,
    year: "22/23",
    languages: ["DE"],
    expires: null,
    active: null,
  },
  {
    id: "kuhn10",
    name: "Preisliste Gesamt ohne Pressen",
    password: "kugeopr236",
    groups: [
      ProductGroups.Trommelmaehler,
      ProductGroups.Scheibenmaeher,
      ProductGroups.Soloaufbereiter,
      ProductGroups.Kreiselheuer,
      ProductGroups.Kreiselschwader,
      ProductGroups.Maishaecksler,
      ProductGroups.Haecksler_Mulcher,
      ProductGroups.Landschaftspflege,
      ProductGroups.Pfluege,
      ProductGroups.Entnahme_Verteilmaschinen,
      ProductGroups.Bodenbearbeitung,
      ProductGroups.Drillmaschinen,
      ProductGroups.Einzelkornsaemaschinen,
      ProductGroups.Futtermischwagen,
      ProductGroups.Feldspritzen,
    ],
    new: false,
    year: "22/23",
    languages: ["DE"],
    expires: null,
    active: null,
  },
  {
    id: "kuhn11",
    name: "Preisliste Gesamt ohne Einzelkornsämaschinen",
    password: "kugeoek236",
    groups: [
      ProductGroups.Trommelmaehler,
      ProductGroups.Scheibenmaeher,
      ProductGroups.Soloaufbereiter,
      ProductGroups.Kreiselheuer,
      ProductGroups.Kreiselschwader,
      ProductGroups.Maishaecksler,
      ProductGroups.Haecksler_Mulcher,
      ProductGroups.Landschaftspflege,
      ProductGroups.Pfluege,
      ProductGroups.Rund_Quaderballenpressen,
      ProductGroups.Entnahme_Verteilmaschinen,
      ProductGroups.Wickler,
      ProductGroups.Bodenbearbeitung,
      ProductGroups.Drillmaschinen,
      ProductGroups.Futtermischwagen,
      ProductGroups.Feldspritzen,
    ],
    new: false,
    year: "22/23",
    languages: ["DE"],
    expires: null,
    active: null,
  },
  {
    id: "kuhn12",
    name: "Preisliste Gesamt de + en",
    password: "kugesen236",
    groups: [
      ProductGroups.Trommelmaehler,
      ProductGroups.Scheibenmaeher,
      ProductGroups.Soloaufbereiter,
      ProductGroups.Kreiselheuer,
      ProductGroups.Kreiselschwader,
      ProductGroups.Maishaecksler,
      ProductGroups.Haecksler_Mulcher,
      ProductGroups.Landschaftspflege,
      ProductGroups.Pfluege,
      ProductGroups.Rund_Quaderballenpressen,
      ProductGroups.Entnahme_Verteilmaschinen,
      ProductGroups.Wickler,
      ProductGroups.Bodenbearbeitung,
      ProductGroups.Drillmaschinen,
      ProductGroups.Einzelkornsaemaschinen,
      ProductGroups.Futtermischwagen,
      ProductGroups.Feldspritzen,
    ],
    new: false,
    year: "22/23",
    languages: ["DE", "EN"],
    expires: null,
    active: null,
  },
  {
    id: "kuhn13",
    name: "Preisliste Gesamt ohne Pressen de + en",
    password: "kugesopren236",
    groups: [
      ProductGroups.Trommelmaehler,
      ProductGroups.Scheibenmaeher,
      ProductGroups.Soloaufbereiter,
      ProductGroups.Kreiselheuer,
      ProductGroups.Kreiselschwader,
      ProductGroups.Maishaecksler,
      ProductGroups.Haecksler_Mulcher,
      ProductGroups.Landschaftspflege,
      ProductGroups.Pfluege,
      ProductGroups.Entnahme_Verteilmaschinen,
      ProductGroups.Wickler,
      ProductGroups.Bodenbearbeitung,
      ProductGroups.Drillmaschinen,
      ProductGroups.Einzelkornsaemaschinen,
      ProductGroups.Futtermischwagen,
      ProductGroups.Feldspritzen,
    ],
    new: false,
    year: "22/23",
    languages: ["DE", "EN"],
    expires: null,
    active: null,
  },
  {
    id: "kuhn14",
    name: "Preisliste nur Pressen de + en",
    password: "kupren236",
    groups: [ProductGroups.Rund_Quaderballenpressen],
    new: false,
    year: "22/23",
    languages: ["DE", "EN"],
    expires: null,
    active: null,
  },
]
