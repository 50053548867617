import React, { FC, useState } from "react"
import { useUser } from "../hooks/useUser"
import { users } from "../data/kuhn-user"

interface FormState {
  user: string;
  password: string;
  error: string | undefined;
}

export const LoginForm: FC = () => {
  const { login } = useUser();
  const [loginFormState, setLoginFormState] = useState<FormState>({
    user: "",
    password: "",
    error: undefined
  });

  const submitForm = async(event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const user = users.find((user) => (
      user.id === loginFormState.user
      && user.password === loginFormState.password
      && (user.active === null || new Date(user.active).getTime() <= new Date().getTime())
      && (user.expires === null || new Date(user.expires).getTime() > new Date().getTime())
    ));
    if (user) {
      login(user);
      setLoginFormState({
        user: '',
        password: '',
        error: undefined
      });
      window.location.reload();
    } else {
      setLoginFormState({
        ...loginFormState,
        error: "Falscher Benutzername oder Passwort"
      });
    }

  }

  const handleUserChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginFormState({
      ...loginFormState,
      user: event.target.value.trim()
    });
  }

  const handlePasswortChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginFormState({
      ...loginFormState,
      password: event.target.value.trim()
    });
  }

  return (
    <div style={{
      maxWidth: 350,
      textAlign: "center",
      margin: "0 auto",
      marginTop: 100,
    }}>
      <h2>HändlerLogin</h2>
      <p>Geben Sie Ihren Benutzernamen und Ihr Passwort ein, um sich an der Website anzumelden:</p>
      <form
        onSubmit={submitForm}
        style={{
          textAlign: "left",
          display: "grid",
          gridTemplateColumns: "2fr 3fr",
          gap: "16px 0",
          fontSize: "1.1em",
          lineHeight: "2em",
        }}
      >
        <label>Benutzername: </label>
        <input type="text" value={loginFormState.user} onChange={handleUserChange} />
        <label>Passwort: </label>
        <input type="password" value={loginFormState.password} onChange={handlePasswortChange} />
        <button style={{gridColumnStart: 1, gridColumnEnd: "span 2", lineHeight: "2em"}} type="submit">Anmelden</button>
        {loginFormState.error && (
          <div style={{gridColumnStart: 1, gridColumnEnd: "span 2", lineHeight: "2em", color: "red"}}>{loginFormState.error}</div>
        )}
      </form>
    </div>
  );
}
