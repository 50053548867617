export enum ProductGroups {
  Trommelmaehler = "trommelmaeher",
  Scheibenmaeher = "scheibenmaeher",
  Soloaufbereiter = "soloaufbereiter",
  Kreiselheuer = "kreiselheuer",
  Kreiselschwader = "kreiselschwader",
  Maishaecksler = "maishaecksler",
  Haecksler_Mulcher = "haecksler_mulcher",
  Landschaftspflege = "landschaftspflege",
  Pfluege = "pfluege",
  Rund_Quaderballenpressen = "rund_quaderballenpressen",
  Entnahme_Verteilmaschinen = "entnahme_verteilmaschinen",
  Wickler = "wickler",
  Bodenbearbeitung = "bodenbearbeitung",
  Drillmaschinen = "drillmaschinen",
  Einzelkornsaemaschinen = "einzelkornsaemaschinen",
  Futtermischwagen = "futtermischwagen",
  Feldspritzen = "feldspritzen",
}
