import React, { FC, useEffect, useState } from "react"
import { data, RemoteCategory } from "../data/kuhn-preislisten"
import { ProductGroups } from "../data/ProductGroups"
import { Helmet } from "react-helmet"
import { useUser } from "../hooks/useUser"

interface ViewState {
  view: "list" | "broschures" | "pricelists"
  category: ProductGroups | undefined
}

export const Liste: FC = () => {
  const { user } = useUser()
  const userLanguages = user
    ? user.languages
      ? user.languages
      : ["DE"]
    : ["DE"]
  const [remoteData, setRemoteData] = useState<RemoteCategory[]>()
  const [view, setView] = useState<ViewState>({
    view: "list",
    category: undefined,
  })

  const navigateToBrochuresPage = (
    event: React.MouseEvent<HTMLAnchorElement>,
    categoryID: ProductGroups
  ): void => {
    event.preventDefault()
    setView({
      view: "broschures",
      category: categoryID,
    })
  }

  const navigateToPricelistPage = (
    event: React.MouseEvent<HTMLAnchorElement>,
    categoryID: ProductGroups
  ): void => {
    event.preventDefault()
    setView({
      view: "pricelists",
      category: categoryID,
    })
  }

  // https://kuhn.cms.mangomedia.at/api/collections/get/productgroups/94a3e54c299b63fe132097eed28edd

  useEffect(() => {
    fetch(
      "https://kuhn.cms.mangomedia.at/api/collections/get/productcategory/?token=94a3e54c299b63fe132097eed28edd"
    )
      .then(result => result.json())
      .then(data => {
        setRemoteData(
          (data.entries as RemoteCategory[])
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter(a => user && a.new == user.new)
            .filter(
              a => user && (!user.year || (user.year && user.year === a.year))
            )
        )
      })
  }, [])

  if (view.view === "list") {
    return (
      <div
        style={{
          textAlign: "left",
        }}
      >
        <Helmet title={user?.name || "Kuhn Preisliste"}></Helmet>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
          }}
        >
          {remoteData &&
            remoteData
              .filter(category => user?.groups.includes(category.id) || false)
              .map(category => {
                const { pricelists, brochures } = category
                const filteredPricelists = pricelists
                  ? pricelists.filter(pricelist => {
                      const pricelistLanguage = pricelist.value.language || "DE"
                      return userLanguages.includes(pricelistLanguage)
                    })
                  : pricelists
                const filteredBrochures = brochures
                  ? brochures.filter(brochure => {
                      const brochureLanguage = brochure.value.language || "DE"
                      return userLanguages.includes(brochureLanguage)
                    })
                  : brochures
                return (
                  <div
                    key={category.id}
                    style={{
                      minWidth: 250,
                      maxWidth: 300,
                      marginTop: 16,
                      marginBottom: 32,
                    }}
                  >
                    <div style={{ height: 40, position: "relative" }}>
                      <h3
                        style={{
                          textAlign: "center",
                          verticalAlign: "bottom",
                          position: "absolute",
                          bottom: 8,
                          margin: 0,
                          width: "100%",
                          fontWeight: 100,
                        }}
                      >
                        {category.name}
                      </h3>
                    </div>

                    <img
                      src={`https://kuhn.cms.mangomedia.at/${category.image.path}`}
                      style={{ width: "100%", marginBottom: 8 }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      {filteredPricelists && filteredPricelists.length === 1 && (
                        <a
                          href={`https://kuhn.cms.mangomedia.at/${filteredPricelists[0].value.file}`}
                          target="_blank"
                        >
                          Preisliste &gt;
                        </a>
                      )}
                      {filteredPricelists && filteredPricelists.length > 1 && (
                        <a
                          href={""}
                          onClick={event =>
                            navigateToPricelistPage(event, category.id)
                          }
                        >
                          Preisliste &gt;
                        </a>
                      )}
                      {filteredBrochures && filteredBrochures.length === 1 && (
                        <a
                          href={`https://kuhn.cms.mangomedia.at/${filteredBrochures[0].value.file}`}
                          target="_blank"
                        >
                          Prospekte &gt;
                        </a>
                      )}
                      {filteredBrochures && filteredBrochures.length > 1 && (
                        <a
                          href={""}
                          onClick={event =>
                            navigateToBrochuresPage(event, category.id)
                          }
                        >
                          Prospekte &gt;
                        </a>
                      )}
                    </div>
                  </div>
                )
              })}
        </div>
        <div
          style={{
            textAlign: "center",
            position: "absolute",
            bottom: 10,
            width: "100%",
          }}
        >
          <a
            href="/kuhn_preislisten/Hinweise zur Preisliste.pdf"
            target="_blank"
          >
            Hinweise zur Preisliste
          </a>{" "}
          |&nbsp;
          <a
            href="/kuhn_preislisten/ALLGEMEINE LIEFERBEDINGUNGEN.pdf"
            target="_blank"
          >
            Allgemeine Lieferbedingungen
          </a>{" "}
          |&nbsp;
          <a href="/kuhn_preislisten/Feldprobeauftrag.pdf" target="_blank">
            Feldprobeauftrag
          </a>
        </div>
      </div>
    )
  }

  if (view.view === "broschures") {
    const activeCategory = data.find(category => category.id === view.category)
    const remoteActiveCategory =
      remoteData && remoteData.find(category => category.id === view.category)
    const backToList = (event: React.MouseEvent<HTMLAnchorElement>): void => {
      event.preventDefault()
      setView({
        view: "list",
        category: undefined,
      })
    }
    if (activeCategory) {
      return (
        <>
          <Helmet title={`Broschüren für ${activeCategory.name}`}></Helmet>
          <h3>Broschüren für {activeCategory.name}</h3>
          <div style={{ padding: "16px 0 32px 0" }}>
            <a href={""} onClick={backToList} style={{ fontSize: "1.2em" }}>
              &lt; zurück
            </a>
          </div>
          <div
            style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr" }}
          >
            {/* activeCategory.brochures.map((brochure, i) => (
            <>
              <div style={{ padding: "0 16px", overflow: "hidden", marginBottom: 8}}>
                <a href={brochure.file} target="_blank">
                  <img src={brochure.preview || "/kuhn_preislisten/No-Image-Placeholder.png"} style={{marginBottom: 8}}/>
                  <div style={{ height: 50}}>{brochure.name}</div>
                </a>
              </div>
            </>
          )) */}
            {remoteActiveCategory &&
              remoteActiveCategory.brochures
                .filter(brochure => {
                  const brochureLanguage = brochure.value.language || "DE"
                  return userLanguages.includes(brochureLanguage)
                })
                .map(brochure => (
                  <>
                    <div
                      style={{
                        /* width: "25%", float: "left", */ padding: "0 16px",
                        overflow: "hidden",
                        marginBottom: 8,
                      }}
                    >
                      <a
                        href={`https://kuhn.cms.mangomedia.at/${brochure.value.file}`}
                        target="_blank"
                      >
                        <img
                          src={
                            brochure.value?.preview?.path
                              ? `https://kuhn.cms.mangomedia.at/${brochure.value?.preview?.path}`
                              : "/kuhn_preislisten/No-Image-Placeholder.png"
                          }
                          style={{ marginBottom: 8 }}
                        />
                        <div style={{ height: 50 }}>{brochure.value.name}</div>
                      </a>
                    </div>
                    {/* i !== 0 && (i + 1) % 4 === 0 && (
                  <div style={{clear:"both", float: "none"}} />
                ) */}
                  </>
                ))}
            <div style={{ clear: "both", float: "none" }} />
          </div>
        </>
      )
    }
  }

  if (view.view === "pricelists") {
    const activeCategory = data.find(category => category.id === view.category)
    const remoteActiveCategory =
      remoteData && remoteData.find(category => category.id === view.category)
    const backToList = (event: React.MouseEvent<HTMLAnchorElement>): void => {
      event.preventDefault()
      setView({
        view: "list",
        category: undefined,
      })
    }
    if (activeCategory) {
      return (
        <>
          <Helmet title={`Preislisten für ${activeCategory.name}`}></Helmet>
          <h3>Preislisten für {activeCategory.name}</h3>
          <div style={{ padding: "16px 0 32px 0" }}>
            <a href={""} onClick={backToList} style={{ fontSize: "1.2em" }}>
              &lt; zurück
            </a>
          </div>
          {/* activeCategory.pricelists.map((pricelist, i) => (
            <>
              <div style={{width: "25%", float: "left", padding: "0 16px", overflow: "hidden", marginBottom: 8}}>
              <a href={`https://kuhn.cms.mangomedia.at/${pricelist.file }`} target="_blank">
                <img src={pricelist.preview || "/kuhn_preislisten/No-Image-Placeholder.png"} style={{marginBottom: 8}}/>
                <div style={{ height: 50}}>{pricelist.name}</div>
              </a>
            </div>
              { i !== 0 && (i + 1) % 4 === 0 && (
                <div style={{clear:"both", float: "none"}} />
              )}
              </>
          )) */}
          {remoteActiveCategory &&
            remoteActiveCategory.pricelists
              .filter(pricelist => {
                const pricelistLanguage = pricelist.value.language || "DE"
                return userLanguages.includes(pricelistLanguage)
              })
              .map((pricelist, i) => (
                <>
                  <div
                    style={{
                      width: "25%",
                      float: "left",
                      padding: "0 16px",
                      overflow: "hidden",
                      marginBottom: 8,
                    }}
                  >
                    <a
                      href={`https://kuhn.cms.mangomedia.at/${pricelist.value.file}`}
                      target="_blank"
                    >
                      <img
                        src={
                          pricelist.value.preview?.path
                            ? `https://kuhn.cms.mangomedia.at/${pricelist.value.preview.path}`
                            : "/kuhn_preislisten/No-Image-Placeholder.png"
                        }
                        style={{ marginBottom: 8 }}
                      />
                      {/* <img src={pricelist.value.preview?.path || "/kuhn_preislisten/No-Image-Placeholder.png"} style={{marginBottom: 8}}/> */}
                      <div style={{ height: 50 }}>{pricelist.value.name}</div>
                    </a>
                  </div>
                  {i !== 0 && (i + 1) % 4 === 0 && (
                    <div style={{ clear: "both", float: "none" }} />
                  )}
                </>
              ))}
        </>
      )
    }
  }

  return <></>
}
